import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Custom Next Arrow
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      style={{
        ...style,
        display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "10px",
        zIndex: 2,
        width: '40px',
        height: '40px',
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    />
  );
};

// Custom Prev Arrow
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      style={{
        ...style,
        // display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "10px",
        zIndex: 2,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    />
  );
};

const Blog = () => {
  const { t } = useTranslation();

  const blogPosts = [
    {
      title: t('blog.post1.title'),
      excerpt: t('blog.post1.excerpt'),
      content: t('blog.post1.content'),
      image: '/images/BlogImages/Eröffnung.jpg',
      date: "25.08.2024",
      format: 'landscape',
    },
    {
      title: t('blog.post2.title'),
      excerpt: t('blog.post2.excerpt'),
      content: t('blog.post2.content'),
      image: '/images/BlogImages/Ferienjob2025.jpg',
      date: "27.08.2024",
      format: 'portrait',
    },
    {
      title: t('blog.post3.title'),
      excerpt: t('blog.post3.excerpt'),
      content: t('blog.post3.content'),
      image: '/images/BlogImages/Dummy1.jpg',
      date: "28.08.2024",
      format: 'landscape',
    },
    // {
    //   title: t('blog.post4.title'),
    //   excerpt: t('blog.post4.excerpt'),
    //   content: t('blog.post4.content'),
    //   image: '/images/blog3.jpg',
    //   date: "XX.XX.XXXX",
    //   format: 'landscape',
    // },
  ];

  const [selectedPost, setSelectedPost] = useState(null);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div id="blog" className="p-8 bg-gray-100 mt-16 mb-16">
      <h2 className="text-3xl font-bold text-center mb-8">{t('blog.title')}</h2>
      <Slider {...settings}>
        {blogPosts.map((post, index) => (
          <div key={index} className="p-2">
            <div
              className="bg-white p-6 rounded-lg shadow-md cursor-pointer flex flex-col h-full justify-between transition-transform transform hover:-translate-y-2"
              onClick={() => handlePostClick(post)}
              style={{ minHeight: '550px', height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <div className="relative mb-4" style={{ flexGrow: 1 }}>
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full rounded-lg h-[40vh] object-cover"
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                <p className="text-gray-600 mb-4" style={{ minHeight: '50px' }}> {/* Mindesthöhe für den Text */}
                  {truncateText(post.excerpt, 100)}
                </p>
              </div>
              <div className="text-gray-500 text-sm mt-auto">
                {post.date}
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {selectedPost && (
        <Modal isOpen={!!selectedPost} onClose={handleCloseModal} post={selectedPost} />
      )}
    </div>
  );
};

export default Blog;
