import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname === '/') {
      // Wenn wir uns auf der Home-Seite befinden, scrolle einfach zu der Sektion
      document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // Wenn wir uns nicht auf der Home-Seite befinden, navigiere zur Home-Seite und scrolle zur Sektion
      navigate('/', { state: { scrollTo: sectionId } });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      // Nur auf der Home-Seite die Scroll-Logik anwenden
      const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
          // Wenn nach unten gescrollt wird, Navbar verstecken
          setShowNavbar(false);
        } else {
          // Wenn nach oben gescrollt wird, Navbar anzeigen
          setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [lastScrollY, location.pathname]);

  // Navbar zurücksetzen, wenn die Seite wechselt
  useEffect(() => {
    setShowNavbar(true);
    setIsMenuOpen(false);
  }, [location.pathname]);

  // Scrolle zur Sektion, wenn die Seite gewechselt wird
  useEffect(() => {
    if (location.pathname === '/' && location.state?.scrollTo) {
      const { scrollTo } = location.state;
      document.getElementById(scrollTo)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname]);

  return (
    <nav className={`bg-gray-800 p-4 fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo */}
        <NavLink to="/" className="text-white text-lg font-semibold flex items-center">
          <img src='/images/Logo.jpeg' width="55" height="25" alt="Logo" />
        </NavLink>

        {/* Navigation Links */}
        <div className="hidden md:flex flex-grow justify-center space-x-4">
          <button onClick={() => scrollToSection('hero')} className="text-gray-300 hover:text-white">
            {t('navbar.home')}
          </button>
          <button onClick={() => scrollToSection('kurse')} className="text-gray-300 hover:text-white">
            {t('navbar.kurse')}
          </button>
          <button onClick={() => scrollToSection('team')} className="text-gray-300 hover:text-white">
            {t('navbar.team')}
          </button>
          <button onClick={() => scrollToSection('kontakt')} className="text-gray-300 hover:text-white">
            {t('navbar.kontakt')}
          </button>
          <a href="https://internationaljobplacement.com" className="text-gray-300 hover:text-white">
            {t('navbar.ijp')}
          </a>
        </div>

        {/* Language Selection and Mobile Menu Button */}
        <div className="flex items-center space-x-4">
          {/* Language Selection */}
          <div className="hidden md:flex items-center space-x-2">
          <button onClick={() => handleLanguageChange('uz')} className="text-gray-300 hover:text-white">
              UZ
            </button>
            <button onClick={() => handleLanguageChange('de')} className="text-gray-300 hover:text-white">
              DE
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-2">
            <button onClick={toggleMenu} className="text-gray-300 hover:text-white">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-gray-800 p-4">
          <button onClick={() => scrollToSection('hero')} className="block text-gray-300 hover:text-white mb-2">
            {t('navbar.home')}
          </button>
          <button onClick={() => scrollToSection('kurse')} className="block text-gray-300 hover:text-white mb-2">
            {t('navbar.kurse')}
          </button>
          <button onClick={() => scrollToSection('team')} className="block text-gray-300 hover:text-white mb-2">
            {t('navbar.team')}
          </button>
          <button onClick={() => scrollToSection('kontakt')} className="block text-gray-300 hover:text-white mb-2">
            {t('navbar.kontakt')}
          </button>
          <a href="https://internationaljobplacement.com" className="block text-gray-300 hover:text-white">
            {t('navbar.ijp')}
          </a>
          {/* Language Selection for Mobile */}
          <div className="flex items-center space-x-2 mt-4">
            <button onClick={() => handleLanguageChange('de')} className="text-gray-300 hover:text-white">
              DE
            </button>
            <button onClick={() => handleLanguageChange('uz')} className="text-gray-300 hover:text-white">
              UZ
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
