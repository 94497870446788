import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Kurse = () => {
  const { t } = useTranslation();

  const kurse = [
    {
      name: t('kurse.a1.name'),
      subtitle: t('kurse.a1.subtitle'),
      description: t('kurse.a1.description'),
      duration: t('kurse.a1.duration'),
      price: t('kurse.a1.price'),
      items: [
        { name: t('kurse.a1.items.a11.name'), description: t('kurse.a1.items.a11.description'), duration: t('kurse.a1.items.a11.duration'), price: t('kurse.a1.items.a11.price') },
        { name: t('kurse.a1.items.a12.name'), description: t('kurse.a1.items.a12.description'), duration: t('kurse.a1.items.a12.duration'), price: t('kurse.a1.items.a12.price') }
      ]
    },
    {
      name: t('kurse.a2.name'),
      subtitle: t('kurse.a2.subtitle'),
      description: t('kurse.a2.description'),
      duration: t('kurse.a2.duration'),
      price: t('kurse.a2.price'),
      items: [
        { name: t('kurse.a2.items.a21.name'), description: t('kurse.a2.items.a21.description'), duration: t('kurse.a2.items.a21.duration'), price: t('kurse.a2.items.a21.price') },
        { name: t('kurse.a2.items.a22.name'), description: t('kurse.a2.items.a22.description'), duration: t('kurse.a2.items.a22.duration'), price: t('kurse.a2.items.a22.price') }
      ]
    },
    {
      name: t('kurse.b1.name'),
      subtitle: t('kurse.b1.subtitle'),
      description: t('kurse.b1.description'),
      duration: t('kurse.b1.duration'),
      price: t('kurse.b1.price'),
      items: [
        { name: t('kurse.b1.items.b11.name'), description: t('kurse.b1.items.b11.description'), duration: t('kurse.b1.items.b11.duration'), price: t('kurse.b1.items.b11.price') },
        { name: t('kurse.b1.items.b12.name'), description: t('kurse.b1.items.b12.description'), duration: t('kurse.b1.items.b12.duration'), price: t('kurse.b1.items.b12.price') }
      ]
    },
    {
      name: t('kurse.b2.name'),
      subtitle: t('kurse.b2.subtitle'),
      description: t('kurse.b2.description'),
      duration: t('kurse.b2.duration'),
      price: t('kurse.b2.price'),
      items: [
        { name: t('kurse.b2.items.b21.name'), description: t('kurse.b2.items.b21.description'), duration: t('kurse.b2.items.b21.duration'), price: t('kurse.b2.items.b21.price') },
        { name: t('kurse.b2.items.b22.name'), description: t('kurse.b2.items.b22.description'), duration: t('kurse.b2.items.b22.duration'), price: t('kurse.b2.items.b22.price') }
      ]
    }
  ];

  return (
    <div id="kurse" className="p-8 bg-white">
      <h2 className="text-4xl font-bold text-center mb-8">{t('kurse.title')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {kurse.map((kurs, index) => (
          <div key={index} className="border border-gray-300 rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-bold mb-2 text-gray-800">{kurs.name}</h3> {/* Hauptüberschrift hervorheben */}
            <p className="text-sm font-semibold text-gray-700 mb-2">{kurs.subtitle}</p> {/* Untertitel hervorheben */}
            <p className="text-lg font-bold mb-2">{t('kurse.description')}</p>
            <p className="mb-2">{kurs.description}</p>
            <p className="text-lg font-bold mb-2">{t('kurse.duration')}</p>
            <p className="mb-2">{kurs.duration}</p>
            <p className="text-lg font-bold mb-2">{t('kurse.price')}</p>
            <p className="mb-4">{kurs.price}</p>
            {kurs.items.map((item, itemIndex) => (
              <div key={itemIndex} className="border-t border-gray-200 pt-4 mt-4">
                <h4 className="text-lg font-semibold">{item.name}</h4>
                <p className="text-sm mb-1">{item.description}</p>
                <p className="text-sm mb-1">{t('kurse.duration')}: {item.duration}</p>
                <p className="text-sm">{t('kurse.price')}: {item.price}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <a
          href="https://t.me/momente_nemis_tili"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center text-white bg-gray-800 hover:bg-gray-600 px-4 py-2 rounded-lg text-xl font-bold"
        >
          <FaTelegramPlane className="mr-2" />
          {t('kurse.telegram')}
        </a>
      </div>
    </div>
  );
};

export default Kurse;
