import React, { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Modal = ({ isOpen, onClose, post }) => {
  const {t} = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    // Schließt das Modal, wenn ein Klick außerhalb des Modals erfolgt
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative max-h-[90vh] overflow-y-auto"
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        >
          <FaTimes className="text-2xl" />
        </button>
        <h2 className="text-2xl font-bold mb-4">{post.title}</h2>
        <div className="relative w-full" style={{ maxHeight: '50vh' }}>
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-auto max-h-[50vh] object-contain rounded-lg"
          />
        </div>
        <p className="text-gray-700 mt-4" dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </div>
  );
};

export default Modal;
