import React from 'react';
import HeroSection from '../components/HeroSection'
import MapComponent from '../components/MapComponent'
import Kurse from '../components/Kurse';
import Team from '../components/Team';
import Vorteile from '../components/Vorteile';
import Blog from '../components/Blog';


const Home = () => {

return(
    <div>
         <HeroSection/>
         <div className="mx-4 md:mx-8 lg:mx-13">
         <Kurse/>
         </div>
         <Vorteile />

             {/* Abstand zwischen HeroSection und Map */}
      <div className="mt-8">
        {/* MapComponent mit Abstand links und rechts */}
        <div className="mx-4 md:mx-8 lg:mx-16">
          <MapComponent />
        </div>
      </div>

      <div className="mx-4 md:mx-8 lg:mx-13">
      <Team/>
      </div>

    <div>
        <Blog/>
    </div>

    </div>
)
}

export default Home;