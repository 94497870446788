// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Importiere dein globales CSS, falls vorhanden
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Importiere die i18n-Konfiguration für die Übersetzungen

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Wenn du Web Vitals messen möchtest, kannst du den Bericht an ein Analytics-Endpunkt senden
reportWebVitals();
