import React from "react";

const Impressum = () => {
  return (
    <div className="container mx-auto px-4 py-20 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 border-b-2 pb-2 border-gray-300">Impressum</h1>
      <div className="space-y-4">
        <p>
          <span className="font-semibold">Firma:</span> IJP Consulting
        </p>
        <p>
          <span className="font-semibold">Adresse:</span>
          <br />
          Bunyodkor shoh ko'chasi 8B,
          <br />
          Tashkent, Toshkent, Usbekistan
        </p>
        <p>
          <span className="font-semibold">Kontakt:</span>
          <br />
          Telefon: +998 71 123 45 67
          <br />
          E-Mail: <a href="mailto:info@ijpconsulting.uz" className="text-blue-500 hover:underline">s.tursunoff@internationaljobplacement.com</a>
        </p>
        <p>
          <span className="font-semibold">Vertreten durch:</span> Salokhiddin Tursunoff
        </p>
        <p>
          <span className="font-semibold">Inhaltlich verantwortlich gemäß § 55 Abs. 2 RStV:</span>
          <br />
          Salokhiddin Tursunoff, Adresse wie oben
        </p>
        <p>
          <span className="font-semibold">Haftungsausschluss:</span>
          <br />
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
          ausschließlich deren Betreiber verantwortlich.
        </p>
        <p>
          <span className="font-semibold">Urheberrecht:</span>
          <br />
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem usbekischen Urheberrecht. Beiträge Dritter sind als
          solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und
          jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
          der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        </p>
      </div>
    </div>
  );
};

export default Impressum;
