import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './locales/de/translation.json';
import translationUZ from './locales/uz/translation.json';

const resources = {
  de: {
    translation: translationDE
  },
  uz: {
    translation: translationUZ
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'uz', // Default language
    fallbackLng: 'de', // Fallback language if the current language is not available
    interpolation: {
      escapeValue: false // React already safely escapes values
    }
  });

export default i18n;
