import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();

  // Dummy-Daten für Teammitglieder, jetzt mit dynamisch geladenen Rollen
  const teamMembers = [
    { name: 'Salokhiddin Tursunov', role: t('team.roles.founder'), photo: '/images/Salo.JPG' },
    { name: 'Shahnoza Smoilova', role: t('team.roles.teacher1'), photo: '/images/Shahnoza.jpg' },
    { name: 'Sakina Nomozova', role: t('team.roles.teacher2'), photo: '/images/Sakina.jpeg' },
    { name: 'Tim Schäfer', role: t('team.roles.cofounder'), photo: '/images/Tim.jpeg' },
  ];

  const { ref: rowRef, inView: rowInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div id="team" className="p-8 bg-white">
      <h2 className="text-3xl font-bold text-center mb-8">{t('team.title')}</h2>
      <div
        className={`grid grid-cols-1 md:grid-cols-4 gap-4 ${rowInView ? 'animate-fade-in' : ''}`}
        ref={rowRef}
      >
        {teamMembers.map((member, index) => (
          <TeamMember key={index} member={member} index={index} />
        ))}
      </div>
    </div>
  );
};

const TeamMember = ({ member, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    delay: index * 200, // Verlangsamt die Animation für jede Kachel individuell
  });


  return (
    <motion.div
      ref={ref}
      className="bg-white p-4 rounded-lg shadow-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.8 }}
    >
      <div className="w-full h-60 overflow-hidden rounded-lg mb-4 relative">
        <img
          src={member.photo}
          alt={member.name}
          className={`w-full h-full object-contain`} // Bild weiter herausgezoomt
        />
      </div>
      <h3 className="text-xl font-semibold text-center">{member.name}</h3>
      <p className="text-gray-600 text-center">{member.role}</p>
    </motion.div>
  );
};

export default Team;
