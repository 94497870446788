import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

// Fix für fehlende Leaflet-Icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const position = [41.30343373727712, 69.23382644425139]; // Koordinaten für Milliy Bog, Tashkent, Usbekistan

const MapComponent = () => {
  const { t } = useTranslation();

  return (
    <div id="kontakt" className="p-8 bg-gray-100 relative">
      <h2 className="text-3xl font-bold text-center mb-4">{t('map.title')}</h2>
      
      <div className="flex flex-col md:flex-row gap-8">
        {/* Kontaktinformationen */}
        <div className="flex-1 flex flex-col items-start space-y-4">
          <div className="flex items-center space-x-2">
            <FaMapMarkerAlt className="text-gray-600" />
            <p>{t('map.address')}: Bunyodkor shoh ko'chasi 8B, Tashkent, Toshkent, Usbekistan</p>
          </div>
          <div className="flex items-center space-x-2">
            <FaPhone className="text-gray-600" />
            <p>{t('map.phone')}: +998 95 67 67 974</p>
          </div>
          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-gray-600" />
            <p>{t('map.email')}: info@momente.uz</p>
          </div>
          {/* Social Media Links */}
          <div className="flex items-center space-x-2 mt-4">
            <a href="https://www.instagram.com/deinprofil" target="_blank" rel="noopener noreferrer" className="flex items-center">
              <FaInstagram className="text-gray-600" />
              <span className="ml-2">{t('map.instagram')}</span>
            </a>
          </div>
          <div className="flex items-center space-x-2 mt-2">
            <a href="https://t.me/deinprofil" target="_blank" rel="noopener noreferrer" className="flex items-center">
              <FaTelegramPlane className="text-gray-600" />
              <span className="ml-2">{t('map.telegram')}</span>
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <FaClock className="text-gray-600" />
            <p className="font-semibold">{t('map.hours')}</p>
          </div>
          <div className="ml-8">
            <p>{t('map.weekdays')}: 08:00 - 21:30</p>
            <p>{t('map.saturday')}: 08:00 - 21:30</p>
            <p>{t('map.sunday')}: {t('map.closed')}</p>
          </div>
        </div>
        
        {/* Karte */}
        <div className="flex-1 relative" id="map-container">
          <MapContainer
            center={position}
            zoom={13}
            style={{ height: '400px', width: '100%' }}
            className="rounded-lg shadow-md"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>
                Milliy Bog, Tashkent, Tashkent Region, Usbekistan
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
