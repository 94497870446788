// src/components/Footer.js
import React from 'react';
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-4">
        {/* Oberer Teil des Footers */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          {/* Logo, Name und Beschreibung */}
          <div className="text-center md:text-left mb-4 md:mb-0">
            <img src="/images/Logo.jpeg" width="55" height="25" alt="Logo" className="mb-2" />
            <p className="text-lg font-semibold">{t('footer.title')}</p>
            <p>{t('footer.description')}</p>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
            <a href="#hero" className="hover:underline">{t('navbar.home')}</a>
            <a href="#kurse" className="hover:underline">{t('navbar.kurse')}</a>
            <a href="#kontakt" className="hover:underline">{t('navbar.kontakt')}</a>
            <a href="#team" className="hover:underline">{t('navbar.team')}</a>
            <a href="https://internationaljobplacement.com" className="hover:underline">{t('navbar.ijp')}</a>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-4">
            <a
              href="https://www.instagram.com/momente_nemis_tili/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:text-gray-400"
            >
              <FaInstagram className="mr-1 text-2xl" /> {t('footer.instagramMomente')}
            </a>
            <a
              href="https://www.instagram.com/internationaljobplacement/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:text-gray-400"
            >
              <FaInstagram className="mr-1 text-2xl" /> {t('footer.instagramIJP')}
            </a>
            <a
              href="https://t.me/momente_nemis_tili"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:text-gray-400"
            >
              <FaTelegramPlane className="mr-1 text-2xl" /> {t('footer.telegramMomente')}
            </a>
            <a
              href="https://t.me/IJP_Uzbekistan"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:text-gray-400"
            >
              <FaTelegramPlane className="mr-1 text-2xl" /> {t('footer.telegramIJP')}
            </a>
          </div>
        </div>

        {/* Impressum und Datenschutz */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-4">
          <div className="flex space-x-4">
            <a
              href="/impressum"
              className="text-sm hover:underline"
            >
              {t('footer.impressum')}
            </a>
            <span>|</span>
            <a
              href="/datenschutz"
              className="text-sm hover:underline"
            >
              {t('footer.datenschutz')}
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center mt-6 border-t border-gray-700 pt-4">
          <p>&copy; 2024 {t('footer.title')}. {t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
