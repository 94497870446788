import React from 'react';
import { FaMapMarkerAlt, FaBriefcase, FaChalkboardTeacher } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Vorteile = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">{t('vorteile.title')}</h2>
        
        <div className="flex flex-col md:flex-row justify-around items-stretch space-y-8 md:space-y-0 md:space-x-4">
          {/* Vorteil 1 */}
          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            <FaMapMarkerAlt className="text-4xl mb-4" />
            <h3 className="text-xl font-semibold">{t('vorteile.benefit1_title')}</h3>
            <p className="mt-2">{t('vorteile.benefit1')}</p>
          </div>

          {/* Vorteil 2 */}
          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            <FaBriefcase className="text-4xl mb-4" />
            <h3 className="text-xl font-semibold">{t('vorteile.benefit2_title')}</h3>
            <p className="mt-2">{t('vorteile.benefit2')}</p>
          </div>

          {/* Vorteil 3 */}
          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            <FaChalkboardTeacher className="text-4xl mb-4" />
            <h3 className="text-xl font-semibold">{t('vorteile.benefit3_title')}</h3>
            <p className="mt-2">{t('vorteile.benefit3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vorteile;
