import React from "react";

const Datenschutz = () => {
  return (
    <div className="container mx-auto px-4 py-20 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 border-b-2 pb-2 border-gray-300">Datenschutzerklärung</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Verantwortlicher</h2>
        <p>
          <strong>Firma:</strong> IJP Consulting
          <br />
          <strong>Adresse:</strong> Bunyodkor shoh ko'chasi 8B, 100096 Tashkent, Usbekistan
          <br />
          <strong>Kontakt:</strong>
          <br />
          Telefon: +998 71 123 45 67
          <br />
          E-Mail: info@ijpconsulting.uz
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>
        <p>
          Beim Besuch unserer Website werden automatisch bestimmte Informationen erfasst, die Ihr Browser an unseren Server übermittelt. Diese Daten werden in sogenannten Logfiles gespeichert. Folgendes wird erfasst:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
        </ul>
        <p>
          Diese Daten werden zu folgenden Zwecken verwendet:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
          <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
          <li>Auswertung der Systemsicherheit und -stabilität</li>
          <li>Administrative Zwecke</li>
        </ul>
        <p>
          Die Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse liegt in der Sicherstellung der Funktionsfähigkeit unserer Website und der Verbesserung des Nutzererlebnisses.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">3. Weitergabe von Daten</h2>
        <p>
          Ihre personenbezogenen Daten werden nicht an Dritte weitergegeben, es sei denn, dass:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>Sie Ihre ausdrückliche Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO gegeben haben</li>
          <li>Eine rechtliche Verpflichtung zur Weitergabe nach Art. 6 Abs. 1 lit. c DSGVO besteht</li>
          <li>Die Weitergabe zur Wahrung unserer berechtigten Interessen nach Art. 6 Abs. 1 lit. f DSGVO erforderlich ist</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">4. Ihre Rechte</h2>
        <p>
          Sie haben das Recht:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>Auskunft über Ihre personenbezogenen Daten zu erhalten (Art. 15 DSGVO)</li>
          <li>Die Berichtigung unrichtiger oder unvollständiger Daten zu verlangen (Art. 16 DSGVO)</li>
          <li>Die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn die gesetzlichen Voraussetzungen erfüllt sind (Art. 17 DSGVO)</li>
          <li>Die Einschränkung der Verarbeitung Ihrer Daten zu verlangen (Art. 18 DSGVO)</li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen (Art. 21 DSGVO)</li>
          <li>Die Datenübertragbarkeit zu verlangen (Art. 20 DSGVO)</li>
        </ul>
        <p>
          Zur Ausübung dieser Rechte wenden Sie sich bitte an die oben angegebene Kontaktadresse.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Änderung der Datenschutzerklärung</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf anzupassen, um den aktuellen rechtlichen Anforderungen zu entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen. Die aktuelle Version der Datenschutzerklärung ist stets auf unserer Website verfügbar.
        </p>
      </section>
    </div>
  );
};

export default Datenschutz;
