import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative w-full h-[80vh] overflow-hidden">
      {/* Desktop Video (Querformat) */}
      <video 
        src='/videos/VideoMomente.MOV' 
        autoPlay 
        loop 
        muted 
        playsInline
        className="absolute inset-0 w-full h-full object-cover hidden md:block" // Verstecke auf mobilen Geräten
      />
      
      {/* Mobile Video (Hochformat) */}
      <video 
        src='/videos/VideoMomente.MOV' 
        autoPlay 
        loop 
        muted 
        playsInline
        className="absolute inset-0 w-full h-full object-cover md:hidden" // Zeige nur auf mobilen Geräten
      />
      
      {/* Text Overlay */}
      <div className="absolute inset-0 flex flex-col justify-center items-center z-10 text-center bg-gray-200 bg-opacity-25 p-4">
        <h1 className="text-gray-900 text-4xl font-bold">Momente</h1>
        <p className="text-gray-900 text-lg mt-2 font-bold">nemis tili markazi</p>
        <p className="text-gray-900 text-sm mt-2 font-bold">by IJP</p>
      </div>
    </div>
  );
};

export default HeroSection;
